import * as S from './styled';

export const Terms = () => (
  <S.PageWrapper>
    <section className={'no-counter'}>
      <h1>Terms of Use</h1>
      <p>Last updated: March 25, 2025</p>
      <p>Welcome to BrainBloom!</p>
      <p>BrainBloom offers users a platform to engage in various brain training exercises and cognitive development activities. Our goal is to help users enhance their mental agility, memory, and problem-solving skills through a variety of fun and challenging games.</p>
      <p>These Terms of Use govern your access to and use of the BrainBloom app (the “<b>App</b>”). By accessing or using the App, you agree to be bound by these Terms of Use. Please read these Terms of Use carefully before you start to use the App. If you do not agree to these Terms of Use, please do not access or use the App.</p>
      <p>We reserve the right to modify, update, or change these Terms of Use at any time. The date of the latest revision will be indicated at the top of this page. Your continued use of the App after any changes to these Terms of Use indicates your acceptance of the updated terms.</p>
      <p>We hope you enjoy using our App to boost your cognitive abilities!</p>
    </section>
    <section>
      <h2>User's Conduct</h2>
      <ol>
        <li><b>Ownership of the App</b>. The App, along with all its updates, enhancements, and versions, including all text, software, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, and related content, is our exclusive property, along with our licensors where applicable. This ownership also includes all associated trademarks, service marks, and trade names, which are either owned, registered, or licensed by us or our licensors.</li>
        <li><b>Usage Conditions</b>. You are granted permission to use the App in accordance with these Terms of Use, provided that you adhere to all stipulations laid out herein.</li>
        <li>
          <b>Prohibited Activities</b>. While using the App, you agree to avoid the following actions:
          <ul className={'dot-list'}>
            <li>Attempting to access, deconstruct, or alter the App's underlying source code without our explicit permission.</li>
            <li>Reproducing, distributing, or duplicating any portion of the App without our prior authorization.</li>
            <li>Engaging in activities that could disrupt the operation of the App, obstruct access for other users, or otherwise cause harm to the App or its users.</li>
            <li>Modifying or removing any copyright notices or proprietary labels included in the App.</li>
            <li>Using the App in any way that is in violation of applicable laws, these Terms of Use, or our Privacy Policy.</li>
            <li>Trying to gain unauthorized access to accounts, systems, or data connected to the App.</li>
            <li>Gathering or extracting data from the App, including user information, without appropriate authorization.</li>
            <li>Participating in any activities that could potentially damage, disable, or negatively impact the functionality of the App or its associated systems.</li>
          </ul>
        </li>
        <li><b>User Content</b>. The “<b>User Content</b>” refers to any materials, including but not limited to text, images, videos, audio, and other forms of content that you choose to create, upload, submit, or share through the App.</li>
        <li><b>License Grant</b>. If you decide to create and submit User Content, you hereby grant us a non-exclusive, sublicensable, transferable, worldwide, perpetual, and royalty-free license to use, copy, modify, exploit, create derivative works from, incorporate into other works, reformat, publicly display, and perform your User Content. This license is granted for the purpose of operating, promoting, and improving our services.</li>
        <li><b>Restrictions</b>. When creating and uploading User Content, you agree not to include any materials that:</li>
        <ul className={'dot-list'}>
          <li>Infringe upon or violate the rights of any individual or entity.</li>
          <li>Contain any expressions of hate, violence, abuse, offensive images or behavior, obscenity, pornography, sexually explicit content, or any material that could result in civil or criminal liability under applicable laws or regulations.</li>
          <li>Offer unauthorized items such as intoxicants, illegal drugs, alcoholic beverages, or other prohibited products.</li>
          <li>Contain software viruses or any other code, files, or programs designed to interrupt, destroy, or limit the functionality of the App.</li>
        </ul>
        <li><b>Third-Party Content</b>. The App may include links to third-party websites or services. We do not endorse or assume any responsibility for any third-party websites, services, or content. Your interactions with such third parties are solely between you and the third party.</li>
      </ol>
    </section>
    <section>
      <h2>Account</h2>
      <ol>
        <li><b>Automatic Account Creation</b>. An Account is automatically created for each player upon using the App to track your progress and achievements. This Account includes only your nickname, which is visible to other players in the rankings and leaderboards. For further details on how your information is managed, please refer to our Privacy Policy.</li>
        <li><b>Account Security</b>. Although the App does not require you to set a password or other credentials, you are responsible for maintaining the confidentiality of your nickname and any other information associated with your Account. Please inform us immediately if you suspect any unauthorized use of your Account or any other security concerns.</li>
        <li><b>Account Deletion</b>. You have the right to delete your Account at any time. Please note that this action is irreversible. Upon deletion, your Account and associated data will be permanently removed from our systems, and no backups or archives will be retained.</li>
      </ol>
    </section>
    <section>
      <h2>Subscription</h2>
      <ol>
        <li><b>Subscription Fee</b>. The subscription fee, which is set for a specific period as indicated in the App, is payable in advance. We retain the right to adjust the subscription fee at our discretion. Should any fee changes occur, they will apply from the next subscription period onward. You will be provided with reasonable notice before any fee adjustments take effect. If you disagree with the revised fee, you may cancel your subscription, and it will remain active until the end of your current subscription period.</li>
        <li><b>Payment Processing</b>. All transactions related to the subscription are handled by the relevant app store (such as the App Store or Google Play) or a third-party payment processor. These entities operate under their own terms of use, privacy policies, and payment conditions, which you should review. We do not take responsibility for the actions or inactions of these payment processors. If you encounter any issues with payment, please contact the payment processor directly.</li>
        <li><b>Automatic Renewal</b>. Your subscription is set to automatically renew at the end of each subscription period unless you cancel it beforehand. You will receive a notification regarding the upcoming renewal, including the applicable fee. Once renewed, the subscription fee for the renewal period is non-refundable, except where required by law. If you choose to cancel your subscription, it will remain active until the current period concludes, and you will not incur charges for the next renewal period.</li>
        <li><b>Purchase Representation</b>. By purchasing a subscription, you confirm that you have the legal right and necessary permissions to make such a purchase.</li>
        <li><b>Non-Refundable Situations</b>. Upon starting a subscription, you acknowledge that subscription fees are non-refundable once the billing cycle starts. While you can cancel your subscription at any time to avoid future charges, refunds will not be issued for unused portions of an active subscription.</li>
        <li><b>Exceptions for Refunds</b>. We may consider issuing refunds if you face technical issues that prevent you from using the App, and report them within 30 days of the issue occurring, our team will review the situation and may approve a refund.</li>
        <li><b>Users in the European Economic Area (EEA)</b>. In compliance with the EU Consumer Rights Directive, EEA users may request a refund within 14 days of the purchase date for any unused subscription. This right to withdraw applies if the service has not been fully used during that time. However, once a subscription is used, including during the trial period, refunds may not be granted under this rule.</li>
        <li><b>Requesting a Refund</b>. If you need to request a refund for purchases made through the website, please contact our support team with the following details:</li>
        <ul className={'dot-list'}>
          <li>Device and operating system information;</li>
          <li>Explanation of the issue or refund request reason;</li>
          <li>Proof of purchase.</li>
        </ul>
      </ol>
      <p>For purchases through the App Store or Google Play, please contact Apple or Google directly, as they manage all refund requests for transactions made via their platform.</p>
    </section>
    <section>
      <h2>No Medical Advice Disclaimer</h2>
      <ol>
        <li>The App and its content are designed for cognitive enhancement and entertainment purposes only. The App is not intended to provide medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider if you have any concerns regarding your mental or physical health.</li>
        <li>The App is designed to help users improve cognitive skills through regular use. However, we make no guarantees regarding specific results or improvements in cognitive abilities as outcomes can vary depending on individual circumstances.</li>
      </ol>
    </section>
    <section>
      <h2>Liability</h2>
      <ol>
        <li><b>No Liability for Certain Damages</b>. To the fullest extent permitted by applicable law, we and our affiliates, officers, directors, employees, and agents (the “<b>Company Parties</b>”) shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data or use, resulting from: (i) your use of or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our application; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the App by any third party; or (v) any errors or omissions in any content or information provided through the App.</li>
        <li><b>Cap on Liability</b>. In no event shall the total liability of the Company Parties, whether in contract, warranty, tort (including negligence), product liability, or any other theory, exceed the amount you have paid, if any, for accessing or using the App during the 6 months preceding the date of the claim.</li>
        <li><b>Exclusions</b>. Some jurisdictions do not allow the exclusion or limitation of certain damages, so the above limitations may not apply to you. In such cases, the liability of the Company Parties shall be limited to the maximum extent permitted by law.</li>
        <li><b>No Warranty</b>. The App is provided on an “as is” and “as available” basis without any warranties of any kind, whether express or implied. We make no representations or warranties, express or implied, regarding the accuracy, reliability, or suitability of the App for any particular purpose. We do not guarantee that the App will meet your requirements, operate uninterrupted, be timely, secure, or error-free. Any reliance on the App and its content is at your own risk.</li>
        <li><b>Force Majeure</b>. Neither party shall be liable for any failure to perform its obligations under these Terms of Use due to events beyond its control, including but not limited to natural disasters, war, pandemics, or other unforeseeable events.</li>
        <li><b>Indemnification</b>. You agree to indemnify, defend, and hold harmless us, our authorized officers, directors, employees, agents, partners, licensors, resellers, successors, and assigns from and against any third-party claim arising from or related to your breach of these Terms of Use or Privacy Policy or your use of the App in violation of applicable laws, rules, or regulations. This includes all liabilities, expenses, damages (actual and consequential), suits, judgments, litigation costs, and attorneys' fees of any kind and nature.</li>
      </ol>
    </section>
    <section>
      <h2>Termination</h2>
      <ol>
        <li><b>Termination by You</b>. You may terminate your use of the App at any time by discontinuing use and uninstalling the application from your device.</li>
        <li><b>Termination by Us</b>. We may terminate or suspend your access to the App at any time, with or without cause, if you violate these Terms of Use or for any other reason at our sole discretion.</li>
        <li><b>Effect of Termination</b>. Upon termination, all rights granted to you under these Terms of Use will immediately cease, and you shall promptly uninstall and cease all use of the application. Termination does not relieve you of any obligations to pay any outstanding fees or charges.</li>
      </ol>
    </section>
    <section>
      {/*<h2>Payments and Refunds ????</h2>*/}
      <ol>
        <li><b>Survival</b>. The sections of these Terms of Use that by their nature should survive termination, including but not limited to “Liability” and “Applicable Law And Dispute Resolution,” will remain in effect after termination.</li>
        <li><b>No Refunds</b>. Except as required by law, all fees paid are non-refundable upon termination.</li>
      </ol>
    </section>
    <section>
      <h2>Applicable Law And Dispute Resolution</h2>
      <ol>
        <li><b>Applicable law</b>. These Terms of Use shall be governed exclusively by the laws of the State of Delaware, USA, without regard to its conflict of laws principles.</li>
        <li><b>Dispute resolution</b>. Any disputes, controversies, or claims arising from or related to these Terms of Use will first be attempted to be resolved through good-faith negotiations. If the parties cannot resolve the dispute through negotiation, the party with the claim must provide written notice to the other party. If the dispute remains unresolved after such notice, it shall be submitted to the courts of the State of Delaware for adjudication.</li>
      </ol>
    </section>
    <section>
      <h2>Miscellaneous</h2>
      <ol>
        <li><b>Severability</b>. If any provision of these Terms of Use is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect. The invalid or unenforceable provision will be modified as necessary to make it valid and enforceable.</li>
        <li><b>Entire Agreement</b>. These Terms of Use represent the entire agreement between you and us concerning the subject matter and supersede all prior agreements and understandings, including any previous Terms of Use.</li>
        <li><b>No Waiver</b>. Our failure to enforce any right or provision of these Terms of Use does not constitute a waiver of such right or provision.</li>
        <li><b>Titles and Interpretation</b>. Clause titles are for convenience only and do not affect the interpretation of these Terms of Use. The term “including” means “including without limitation.”</li>
        <li><b>Assignment</b>. You may not assign these Terms of Use or any rights or obligations under them without our prior written consent. Any attempted assignment without consent is void. We may assign these Terms of Use freely. They will be binding upon and benefit the parties and their respective successors and assigns.</li>
      </ol>
    </section>
    <section>
      <h2>Contact Details</h2>
      <p>FINDMY LLC, 651 N BROAD ST STE 205 6446 MIDDLETOWN, DE 19709</p>
      <p>APPLYFT LTD: Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus; +1 (650) 319-7416</p>
      <p><a href="mailto:support@brainbloom.me">support@brainbloom.me</a></p>
    </section>
  </S.PageWrapper>
);
