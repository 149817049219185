import * as S from './styled';

export const PrivacyPolicy = () => (
  <S.PageWrapper>
    <section className={'no-counter'}>
      <h1>Privacy Policy</h1>
      <p>Last updated: March 25, 2025</p>
      <p>Welcome to BrainBloom!</p>
      <p>At BrainBloom (the “<b>App</b>”), your privacy is a top priority. This Privacy Policy explains what information we collect from you, how we use it, and how we protect it while you use our App. We are dedicated to managing your personal information securely and responsibly.</p>
      <p>By using the App, you agree to the terms outlined in this Privacy Policy. If you disagree with any part of this policy, please refrain from using our App.</p>
      <p>We may periodically update this Privacy Policy to align with changes in our practices, technology, or legal requirements. We recommend reviewing this policy regularly to stay informed about how we handle your information. Continued use of our App following any changes signifies your acceptance of the revised terms.</p>
    </section>
    <section>
      <h2>Purposes of Data Collection</h2>
      <p>We collect and use your personal information for the following purposes:</p>
      <table>
        <thead>
          <tr>
            <th>Purpose</th>
            <th>Processed Personal Information</th>
            <th>Description</th>
            <th>Legal Basis for Processing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Providing the App</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Device Data</b>: This includes information about your device, such as the time of access, device ID, country, language settings, time zone, device model, operating system, and browser type.</li>
                <li><b>Usage Data</b>: We track actions you take within the App to understand how you interact with our services.</li>
                <li><b>Subscription Details</b>: We collect basic information about your subscription. Note that payment details are managed directly by payment providers and are not stored by us.</li>
              </ul>
            </td>
            <td>We use this information to operate and maintain the core functionalities of the App.</td>
            <td>Contract (Terms of Use)</td>
          </tr>
          <tr>
            <td><b>Providing App Features</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Account Data</b>: This includes your nickname and any other information you provide when creating an account.</li>
                <li><b>In-App Progress</b>: We monitor and store your progress within the App to help track your achievements and provide personalized feedback.</li>
              </ul>
            </td>
            <td>
              <p>We utilize this information to enhance app features, including curating content that matches your performance and preferences, offering a personalized experience and ranking players and creating leaderboards.</p>
              <p>Please note that leaderboards are public. If you prefer not to be identifiable, we recommend choosing a nickname that does not reveal your identity.</p>
            </td>
            <td>Contract (Terms of Use)</td>
          </tr>
          <tr>
            <td><b>Tracking Performance Issues</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Device Data</b></li>
                <li><b>Usage Data</b></li>
                <li><b>Crash Data</b>: Information about the App and device status at the time of a crash, including logs, stack traces, and error messages.</li>
              </ul>
            </td>
            <td>This data helps us monitor, identify, and resolve performance issues, ensuring a stable and reliable user experience.</td>
            <td>Contract (Terms of Use)</td>
          </tr>
          <tr>
            <td><b>Analytics</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Device Data</b></li>
                <li><b>Usage Data</b></li>
              </ul>
            </td>
            <td>We analyze this information to understand user behavior, improve our services, and develop new features that align with user preferences.</td>
            <td>
              <p>Legitimate interest:</p>
              <ul className={'line-list'}>
                <li>Your interest: Receiving updates tailored to your needs.</li>
                <li>Our interest: Improving the App</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td><b>Technical Support</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Email</b></li>
                <li><b>Inquiry Content</b>: Details of your support request.</li>
              </ul>
            </td>
            <td>We use this information to respond to your inquiries and provide customer support, ensuring any issues or questions are addressed promptly.</td>
            <td>Contract (Terms of Use)</td>
          </tr>
          <tr>
            <td><b>Email Newsletters</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Email</b></li>
              </ul>
            </td>
            <td>This data is used to send you newsletters with updates, promotions, and other information related to the App. You can opt out of these communications at any time.</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td><b>Payment Processing</b></td>
            <td>
              <ul className={'line-list'}>
                <li><b>Payment Related Data</b>: card number last 4 digits, date and time of purchase, purchased package</li>
              </ul>
            </td>
            <td>Please note that for payment processing we use third-party payment providers as listed below. If you purchase through the app store, your payments are processed directly by the app store. We do not store your payment data.</td>
            <td>Contract (Terms of Use)</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <h2>Sharing of Personal Information</h2>
      <ol>
        <li>
          We may share your personal information with select third parties to improve our services and provide you with a better user experience. Below are the categories of third parties with whom we may share your information, along with the purposes for sharing:
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Purpose</th>
                <th>Third Parties</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td><b>Analytics Platforms</b></td>
              <td>To analyze usage patterns, monitor performance, and improve our services.</td>
              <td>
                <ul className={'line-list'}>
                  <li><a href={'https://www.appsflyer.com/legal/services-privacy-policy/'} target={'_blank'} rel={'noreferrer'}>AppsFlyer</a></li>
                  <li><a href={'https://amplitude.com/privacy'} target={'_blank'} rel={'noreferrer'}>Amplitude</a></li>
                  <li><a href={'https://policies.google.com/technologies/partner-sites'} target={'_blank'} rel={'noreferrer'}>Google Analytics</a></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><b>Advertising Networks</b></td>
              <td>To deliver advertisements, including those based on your interests and interactions with our services.</td>
              <td>
                <ul className={'line-list'}>
                  <li><a href={'https://www.facebook.com/privacy/policy'} target={'_blank'} rel={'noreferrer'}>Meta Platforms, Inc.</a></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><b>Crash Reporting Tools</b></td>
              <td>To identify and resolve technical issues, ensuring a more stable and reliable user experience.</td>
              <td>
                <ul className={'line-list'}>
                  <li><a href={'https://firebase.google.com/support/privacy'} target={'_blank'} rel={'noreferrer'}>Firebase Crashlytics</a></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><b>Payment Providers</b></td>
              <td>For payment processing we use third-party payment providers which may change from time to time. If you purchase through the app store, your payments are processed directly by the app store.</td>
              <td>
                <ul className={'line-list'}>
                  <li><a href={'https://stripe.com/en-cy/privacy'} target={'_blank'} rel={'noreferrer'}>Stripe</a></li>
                  <li><a href={'https://www.paypal.com/cy/legalhub/braintree/braintree-privacy-policy?utm_campaign=Braintree_Migration&utm_medium=Website&utm_source=Braintree'} target={'_blank'} rel={'noreferrer'}>Braintree</a></li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </li>
        <li>We may also disclose your personal information if required by law or in response to valid legal processes, such as subpoenas, court orders, or government requests. In such cases, we will only share the information necessary to fulfill our legal obligations and will take appropriate measures to ensure the confidentiality and security of your personal information.</li>
      </ol>
    </section>
    <section>
      <h2>Personal Information Storage</h2>
      <ol>
        <li>
          <b>Data Management</b><br/>
          All personal information is stored securely in Firebase. Firebase is committed to data protection and may retain your data in accordance with its policies. For more information on Firebase's data handling practices, please refer to their <a href={'https://firebase.google.com/support/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.
        </li>
        <li>
          <b>Storage Duration</b><br/>
          We retain your personal information for no longer than 3 years from the date of your last login, unless extended retention is necessary for legal, regulatory, or legitimate business reasons. If you choose to delete your account or request the removal of your personal information, we will promptly fulfill your request, and your information will be deleted before the end of the retention period.
        </li>
      </ol>
    </section>
    <section>
      <h2>Your Rights regarding Personal Information</h2>
      <ol>
        <li>
          <b>Your Rights Regarding Personal Information (For California Residents)</b><br/><br/>
          <table>
            <thead>
              <tr>
                <th>Right</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Right to Know</b></td>
                <td>You can request information about the personal information we collect, use, disclose, and sell, including the categories of data and sources.</td>
              </tr>
              <tr>
                <td><b>Right to Access</b></td>
                <td>You can request access to the personal information we have collected about you in the past 12 months.</td>
              </tr>
              <tr>
                <td><b>Right to Deletion</b></td>
                <td>You may request the deletion of your personal information, subject to certain exceptions.</td>
              </tr>
              <tr>
                <td><b>Right to Opt-Out</b></td>
                <td>You can opt out of the sale of your personal information. If we sell your data, you can request to stop it.</td>
              </tr>
              <tr>
                <td><b>Right to Non-Discrimination</b></td>
                <td>We will not discriminate against you for exercising your rights under the CCPA, including denying services or providing a different level of service.</td>
              </tr>
              <tr>
                <td><b>Right to Data Portability</b></td>
                <td>You can request to receive your personal information in a portable format to transfer it to another entity.</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          <b>Your Rights Regarding Personal Information (For EU/EEA and UK Residents)</b><br/><br/>
          <table>
            <thead>
              <tr>
                <th>Right</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Access and Correction</b></td>
                <td>You can access the personal information we hold about you and request corrections if it is inaccurate or incomplete.</td>
              </tr>
              <tr>
                <td><b>Deletion</b></td>
                <td>You may request the deletion of your personal information under certain conditions, such as when it is no longer needed.</td>
              </tr>
              <tr>
                <td><b>Data Portability</b></td>
                <td>You can receive your personal information in a structured, commonly used format and transmit it to another organization.</td>
              </tr>
              <tr>
                <td><b>Restriction of Processing</b></td>
                <td>You can request a restriction on the processing of your personal information under specific conditions.</td>
              </tr>
              <tr>
                <td><b>Objection to Processing</b></td>
                <td>You have the right to object to the processing of your personal information based on legitimate interests or for direct marketing.</td>
              </tr>
              <tr>
                <td><b>Withdraw Consent</b></td>
                <td>If processing is based on consent, you can withdraw your consent at any time. This will not affect the lawfulness of processing before withdrawal.</td>
              </tr>
              <tr>
                <td><b>Complaints</b></td>
                <td>You can lodge a complaint with a relevant data protection authority if you believe we are not complying with data protection laws.</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>To exercise any of these rights or if you have questions, please contact us using the information in the “Contact Us” section of this Privacy Policy.</li>
      </ol>
    </section>
    <section>
      <h2>Cookie Usage on Our Site</h2>
      <ol>
        <li><b>What Are Cookies?</b> Cookies are tiny pieces of data sent from a website and stored on your device while you browse. They help our site remember your actions and preferences, making your visit smoother and more personalized.</li>
        <li>
          <b>Our Cookie Categories</b>
          <ul className={'dot-list'}>
            <li><b>Essential Cookies</b>: These are crucial for the basic operation of our site. Without them, you might not be able to use all the features.</li>
            <li><b>Analytical Cookies</b>: These help us gather data on how you interact with our site, such as which pages you visit most often, so we can make improvements.</li>
          </ul>
        </li>
        <li>
          <b>Managing Your Preferences</b><br/>
          We operate on a consent-based model for non-essential cookies. By default, these cookies are disabled. You have the option to enable them through our cookie management tool. Restricting cookies to only the essentials may affect the functionality of some features.
        </li>
        <li>
          <b>Customizing Your Cookie Settings</b><br/>
          <p>You can adjust your cookie preferences directly through your browser settings. Here are resources to guide you:</p>
          <ul className={'dot-list'}>
            <li><a href={'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'} target={'_blank'} rel={'noreferrer'}>Adjust Cookies in Firefox</a></li>
            <li><a href={'https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'} target={'_blank'} rel={'noreferrer'}>Adjust Cookies in Chrome</a></li>
            <li><a href={'https://support.apple.com/en-us/HT201265'} target={'_blank'} rel={'noreferrer'}>Adjust Cookies in Safari</a></li>
            <li><a href={'https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy'} target={'_blank'} rel={'noreferrer'}>Adjust Cookies in Edge</a></li>
            <li><a href={'https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer'} target={'_blank'} rel={'noreferrer'}>Adjust Cookies in Internet Explorer</a></li>
          </ul>
          <p>For further details on cookies, including management and deletion, visit <a href={'https://www.aboutcookies.org/'} target={'_blank'} rel={'noreferrer'}>AboutCookies.org</a> or <a href={'http://www.allaboutcookies.org/'} target={'_blank'} rel={'noreferrer'}>AllAboutCookies.org</a>.</p>
        </li>
      </ol>
    </section>
    <section>
      <h2>Contact Us</h2>
      <p>FINDMY LLC, 651 N BROAD ST STE 205 6446 MIDDLETOWN, DE 19709</p>
      <p>APPLYFT LTD: Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus; +1 (650) 319-7416</p>
      <p><a href="mailto:support@brainbloom.me">support@brainbloom.me</a></p>
    </section>
  </S.PageWrapper>
);
